/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Login,
  Login2fa,
  LoginResponse,
  ResetPasswordCheckToken,
  ResetPasswordSendMail,
  ResetPasswordSetPassword,
  TwoFactorAuthVerify,
} from '../models';
import {
    LoginFromJSON,
    LoginToJSON,
    Login2faFromJSON,
    Login2faToJSON,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    ResetPasswordCheckTokenFromJSON,
    ResetPasswordCheckTokenToJSON,
    ResetPasswordSendMailFromJSON,
    ResetPasswordSendMailToJSON,
    ResetPasswordSetPasswordFromJSON,
    ResetPasswordSetPasswordToJSON,
    TwoFactorAuthVerifyFromJSON,
    TwoFactorAuthVerifyToJSON,
} from '../models';

export interface ApiGrexmanagerUserLoginCreateRequest {
    login: Login;
}

export interface ApiGrexmanagerUserLoginMfaCreateRequest {
    login2fa: Login2fa;
}

export interface ApiGrexmanagerUserMfaVerifyCreateRequest {
    twoFactorAuthVerify: TwoFactorAuthVerify;
}

export interface ApiGrexmanagerUserResetPasswordCheckTokenCreateRequest {
    resetPasswordCheckToken: ResetPasswordCheckToken;
}

export interface ApiGrexmanagerUserResetPasswordSendTokenCreateRequest {
    resetPasswordSendMail: ResetPasswordSendMail;
}

export interface ApiGrexmanagerUserResetPasswordSetPasswordCreateRequest {
    resetPasswordSetPassword: ResetPasswordSetPassword;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Login user
     */
    async apiGrexmanagerUserLoginCreateRaw(requestParameters: ApiGrexmanagerUserLoginCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        if (requestParameters.login === null || requestParameters.login === undefined) {
            throw new runtime.RequiredError('login','Required parameter requestParameters.login was null or undefined when calling apiGrexmanagerUserLoginCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/grexmanager/user/login/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginToJSON(requestParameters.login),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Login user
     */
    async apiGrexmanagerUserLoginCreate(requestParameters: ApiGrexmanagerUserLoginCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.apiGrexmanagerUserLoginCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Login user
     */
    async apiGrexmanagerUserLoginMfaCreateRaw(requestParameters: ApiGrexmanagerUserLoginMfaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.login2fa === null || requestParameters.login2fa === undefined) {
            throw new runtime.RequiredError('login2fa','Required parameter requestParameters.login2fa was null or undefined when calling apiGrexmanagerUserLoginMfaCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/grexmanager/user/login/mfa/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Login2faToJSON(requestParameters.login2fa),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Login user
     */
    async apiGrexmanagerUserLoginMfaCreate(requestParameters: ApiGrexmanagerUserLoginMfaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexmanagerUserLoginMfaCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout user
     */
    async apiGrexmanagerUserLogoutCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/logout/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Logout user
     */
    async apiGrexmanagerUserLogoutCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexmanagerUserLogoutCreateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Disable 2fa
     */
    async apiGrexmanagerUserMfaDisableCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/mfa/disable/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Disable 2fa
     */
    async apiGrexmanagerUserMfaDisableCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexmanagerUserMfaDisableCreateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set up 2fa
     */
    async apiGrexmanagerUserMfaSetupCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/mfa/setup/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Set up 2fa
     */
    async apiGrexmanagerUserMfaSetupCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexmanagerUserMfaSetupCreateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Verify 2fa
     */
    async apiGrexmanagerUserMfaVerifyCreateRaw(requestParameters: ApiGrexmanagerUserMfaVerifyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.twoFactorAuthVerify === null || requestParameters.twoFactorAuthVerify === undefined) {
            throw new runtime.RequiredError('twoFactorAuthVerify','Required parameter requestParameters.twoFactorAuthVerify was null or undefined when calling apiGrexmanagerUserMfaVerifyCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/mfa/verify/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TwoFactorAuthVerifyToJSON(requestParameters.twoFactorAuthVerify),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Verify 2fa
     */
    async apiGrexmanagerUserMfaVerifyCreate(requestParameters: ApiGrexmanagerUserMfaVerifyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexmanagerUserMfaVerifyCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check reset password token
     */
    async apiGrexmanagerUserResetPasswordCheckTokenCreateRaw(requestParameters: ApiGrexmanagerUserResetPasswordCheckTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.resetPasswordCheckToken === null || requestParameters.resetPasswordCheckToken === undefined) {
            throw new runtime.RequiredError('resetPasswordCheckToken','Required parameter requestParameters.resetPasswordCheckToken was null or undefined when calling apiGrexmanagerUserResetPasswordCheckTokenCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/reset-password/check-token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordCheckTokenToJSON(requestParameters.resetPasswordCheckToken),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Check reset password token
     */
    async apiGrexmanagerUserResetPasswordCheckTokenCreate(requestParameters: ApiGrexmanagerUserResetPasswordCheckTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexmanagerUserResetPasswordCheckTokenCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send reset password email
     */
    async apiGrexmanagerUserResetPasswordSendTokenCreateRaw(requestParameters: ApiGrexmanagerUserResetPasswordSendTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.resetPasswordSendMail === null || requestParameters.resetPasswordSendMail === undefined) {
            throw new runtime.RequiredError('resetPasswordSendMail','Required parameter requestParameters.resetPasswordSendMail was null or undefined when calling apiGrexmanagerUserResetPasswordSendTokenCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/reset-password/send-token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordSendMailToJSON(requestParameters.resetPasswordSendMail),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Send reset password email
     */
    async apiGrexmanagerUserResetPasswordSendTokenCreate(requestParameters: ApiGrexmanagerUserResetPasswordSendTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexmanagerUserResetPasswordSendTokenCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set new password
     */
    async apiGrexmanagerUserResetPasswordSetPasswordCreateRaw(requestParameters: ApiGrexmanagerUserResetPasswordSetPasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.resetPasswordSetPassword === null || requestParameters.resetPasswordSetPassword === undefined) {
            throw new runtime.RequiredError('resetPasswordSetPassword','Required parameter requestParameters.resetPasswordSetPassword was null or undefined when calling apiGrexmanagerUserResetPasswordSetPasswordCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/user/reset-password/set-password/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordSetPasswordToJSON(requestParameters.resetPasswordSetPassword),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Set new password
     */
    async apiGrexmanagerUserResetPasswordSetPasswordCreate(requestParameters: ApiGrexmanagerUserResetPasswordSetPasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexmanagerUserResetPasswordSetPasswordCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
