/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetPasswordSetPassword
 */
export interface ResetPasswordSetPassword {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordSetPassword
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordSetPassword
     */
    resetPasswordToken: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordSetPassword
     */
    resetPassword2faToken?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordSetPassword
     */
    newPassword: string;
}

/**
 * Check if a given object implements the ResetPasswordSetPassword interface.
 */
export function instanceOfResetPasswordSetPassword(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "resetPasswordToken" in value;
    isInstance = isInstance && "newPassword" in value;

    return isInstance;
}

export function ResetPasswordSetPasswordFromJSON(json: any): ResetPasswordSetPassword {
    return ResetPasswordSetPasswordFromJSONTyped(json, false);
}

export function ResetPasswordSetPasswordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordSetPassword {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'resetPasswordToken': json['reset_password_token'],
        'resetPassword2faToken': !exists(json, 'reset_password_2fa_token') ? undefined : json['reset_password_2fa_token'],
        'newPassword': json['new_password'],
    };
}

export function ResetPasswordSetPasswordToJSON(value?: ResetPasswordSetPassword | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'reset_password_token': value.resetPasswordToken,
        'reset_password_2fa_token': value.resetPassword2faToken,
        'new_password': value.newPassword,
    };
}

