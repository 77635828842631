import "./BreadCrumbs.css";
import React from "react";
import { FiArrowLeft } from "react-icons/fi";

interface BreadCrumbsProps {
  data: {
    title: string;
    callback?: () => void;
  }[];
}

export default class BreadCrumbs extends React.Component<BreadCrumbsProps> {
  render() {
    return (
      <div id="BreadCrumbs-Container">
        {(() => {
          if (this.props.data.length >= 2) {
            return (
              <div
                className="BreadCrumbs-Button"
                id="BreadCrumbs-BackButton"
                onClick={() => {
                  if (this.props.data[this.props.data.length - 2].callback) {
                    this.props.data[this.props.data.length - 2].callback!();
                  }
                }}
              >
                <FiArrowLeft />
              </div>
            );
          }
        })()}

        {this.props.data.map((item, index) => {
          if (index === this.props.data.length - 1) {
            return (
              <div
                key={index}
                className="BreadCrumbs-Button BreadCrumbs-CurrentPageButton"
                onClick={() => {
                  if (item.callback) {
                    item.callback();
                  }
                }}
              >
                {item.title}
              </div>
            );
          } else {
            return (
              <span key={index}>
                <div
                  className="BreadCrumbs-Button"
                  onClick={() => {
                    if (item.callback) {
                      item.callback();
                    }
                  }}
                >
                  {item.title}
                </div>
                <div className="BreadCrumbs-Arrow">{">"}</div>
              </span>
            );
          }
        })}
      </div>
    );
  }
}
