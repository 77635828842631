import "./GrexManagerLogo.css";
import React from "react";

interface GrexManagerLogoProps {
  onClick?: () => void;
}

export default class GrexManagerLogo extends React.Component<GrexManagerLogoProps> {
  render() {
    return (
      <div
        id="GrexManagerLogo-Container"
        style={this.props.onClick !== undefined ? { cursor: "pointer" } : {}}
        onClick={this.props.onClick}
      >
        <svg version="1.1" id="GrexManagerLogo-Logo" viewBox="0 0 790 200">
          <path
            d="m 107.78515,169.98633 c -1.30068,0 -2.36328,1.0641 -2.36328,2.36328 v 8.24805 c 0,1.30068 1.0626,2.36328 2.36328,2.36328 h 8.24806 c 1.29918,0 2.36522,-1.0626 2.36522,-2.36328 v -1.55665 h -8.78905 c -0.14801,0 -0.26758,-0.11948 -0.26758,-0.26757 v -8.78711 z"
            style={{ fill: "#00a1e0" }}
          />
          <path
            d="m 118.3133,179.04168 h -8.75493 c -0.1196,0 -0.21678,-0.0972 -0.21678,-0.21678 v -8.75494 c 0,-0.0463 0.0374,-0.0837 0.0837,-0.0837 h 7.32269 c 0.90749,0 1.64902,0.74154 1.64902,1.64903 v 7.32269 c 0,0.0463 -0.0374,0.0837 -0.0837,0.0837"
            style={{ fill: "#b8daf3" }}
          />
          <path
            d="m 82.335938,167.69141 v 23.20117 c -10e-7,0.10721 -0.08616,0.19336 -0.19336,0.19336 H 58.941407 v 6.55078 c 0,1.30068 1.064095,2.36328 2.363281,2.36328 h 27.582036 c 1.299187,0 2.363279,-1.0626 2.363279,-2.36328 v -27.58008 c 0,-1.30068 -1.064092,-2.36523 -2.363279,-2.36523 z"
            style={{ fill: "#00a1e0" }}
          />
          <path
            d="m 82.165414,191.08659 h -23.15363 c -0.03887,0 -0.07027,-0.0314 -0.07027,-0.0718 v -21.61075 c 0,-0.94188 0.769943,-1.71331 1.711816,-1.71331 h 21.610753 c 0.04037,0 0.07176,0.0329 0.07176,0.0718 v 23.15362 c 0,0.0942 -0.07625,0.17044 -0.170433,0.17044"
            style={{ fill: "#b8daf3" }}
          />
          <path
            d="m 32.382813,160.88672 c -1.30068,0 -2.365235,1.0626 -2.365235,2.36328 v 2.24609 h 13.32422 c 0.121013,0 0.220701,0.0997 0.220701,0.22071 v 13.32421 h 2.246096 c 1.299185,0 2.36328,-1.0626 2.36328,-2.36328 V 163.25 c 0,-1.30068 -1.064095,-2.36328 -2.36328,-2.36328 z"
            style={{ fill: "#00a1e0" }}
          />
          <path
            d="M 43.521411,179.04168 H 31.78089 c -0.970279,0 -1.764143,-0.79387 -1.764143,-1.76415 v -11.74052 c 0,-0.0224 0.01793,-0.0404 0.04036,-0.0404 h 13.305825 c 0.109134,0 0.19884,0.0897 0.19884,0.19884 v 13.30583 c 0,0.0224 -0.01795,0.0404 -0.04037,0.0404"
            style={{ fill: "#b8daf3" }}
          />
          <path
            d="m 6.5761725,157.02734 c -0.4141251,0 -0.751954,0.33588 -0.751954,0.75 v 7.57618 c 0,0.41412 0.3378289,0.75195 0.751954,0.75195 h 1.6484362 v -6.55078 c 0,-0.0717 0.055287,-0.12891 0.1269587,-0.12891 h 6.5507806 v -1.64844 c 0,-0.41412 -0.335875,-0.75 -0.75,-0.75 z"
            style={{ fill: "#00a1e0" }}
          />
          <path
            d="M 14.598884,166.10468 H 8.2793645 c -0.031396,0 -0.055316,-0.0254 -0.055316,-0.0553 v -6.53481 c 0,-0.0493 0.038871,-0.0897 0.088207,-0.0897 H 14.84706 c 0.0314,0 0.05681,0.0254 0.05681,0.0553 v 6.31952 c 0,0.16894 -0.137544,0.30498 -0.304988,0.30498"
            style={{ fill: "#b8daf3" }}
          />
          <path
            d="M 2.3632807,77.363283 C 1.0626005,77.363283 3.5651014e-7,78.429323 3.5651014e-7,79.72851 v 68.85157 c 0,1.30068 1.06260014348986,2.36328 2.36328034348986,2.36328 H 18.708985 V 96.109375 c 0,-0.0192 0.01616,-0.03516 0.03711,-0.03516 h 54.83398 V 79.72851 c 0,-1.299187 -1.064097,-2.36524 -2.363282,-2.36524 z"
            style={{ fill: "#00a1e0" }}
          />
          <path
            d="m 73.579718,149.18146 c 0,0.97028 -0.793864,1.76265 -1.762648,1.76265 H 18.794106 c -0.04635,0 -0.08521,-0.0374 -0.08521,-0.0852 V 96.104682 c 0,-0.01644 0.01345,-0.0314 0.0314,-0.0314 h 53.075291 c 0.970278,0 1.764142,0 1.764142,0 0,0 0,0.793864 0,1.764142 z"
            style={{ fill: "#b8daf3" }}
          />
          <path
            d="m 25.890625,50.726564 v 14.666013 c 0,0.0053 -0.0037,0.012 -0.0097,0.012 H 11.212939 v 2.37696 c 0,1.625107 1.329975,2.95312 2.955078,2.95312 h 14.099609 c 1.625103,0 2.955079,-1.328013 2.955079,-2.95312 V 53.681924 c 0,-1.625107 -1.329976,-2.95508 -2.955079,-2.95508 z"
            style={{ fill: "#00a1e0" }}
          />
          <path
            d="m 11.213673,52.893937 c 0,-1.191546 0.974763,-2.166306 2.166308,-2.166306 h 12.384882 c 0.07027,0 0.125587,0.05533 0.125587,0.124133 v 14.54372 c 0,0.004 -0.0029,0.0093 -0.0075,0.0093 H 11.339256 c -0.06877,0 -0.125583,-0.0568 -0.125583,-0.1256 z"
            style={{ fill: "#b8daf3" }}
          />
          <path
            d="m 108.39259,40.191404 c -1.29919,0 -2.3633,1.064094 -2.3633,2.36328 v 8.248053 c 0,1.30068 1.06411,2.36328 2.3633,2.36328 h 1.15624 v -9.353519 c 0,-0.05627 0.0472,-0.101467 0.10345,-0.101467 h 9.35352 v -1.156267 c 0,-1.299186 -1.06456,-2.36328 -2.36524,-2.36328 z"
            style={{ fill: "#00a1e0" }}
          />
          <path
            d="m 119.00565,43.796938 v 7.647106 c 0,0.947853 -0.77592,1.722293 -1.72228,1.722293 h -7.64711 c -0.0478,0 -0.0867,-0.03893 -0.0867,-0.08667 v -9.296133 c 0,-0.0404 0.0329,-0.0732 0.0733,-0.0732 h 9.29614 c 0.0478,0 0.0867,0.03893 0.0867,0.08667"
            style={{ fill: "#b8daf3" }}
          />
          <path
            d="m 108.39453,-7.933333e-6 c -1.30068,0 -2.36524,1.062613333333 -2.36524,2.363293233333 v 4.3867066 c 0.0329,0 0.0274,0 0.0605,0 h 19.12892 c 0.006,0 0.012,0.00533 0.012,0.012 V 25.890911 c 0,0.03333 1e-5,0.0276 0,0.06053 h 4.38672 c 1.29918,0 2.36524,-1.0626 2.36524,-2.36328 V 2.3635653 c 0,-1.3006799 -1.06606,-2.36329323334 -2.36524,-2.36329323334 z"
            style={{ fill: "#00a1e0" }}
          />
          <path
            d="m 125.22889,25.950685 c 0,0 -0.78788,0 -1.74919,0 h -15.70087 c -0.96281,0 -1.7492,-0.786387 -1.7492,-1.749187 V 8.5021119 c 0,-0.9628 0,-1.7491867 0,-1.75068 0.001,0 0.78938,-0.00133 1.75069,-0.00133 h 17.4396 c 0.006,0 0.0104,0.00533 0.0104,0.010667 V 24.200005 c 0,0.9628 -0.001,1.75068 -0.001,1.75068"
            style={{ fill: "#b8daf3" }}
          />
          <path
            d="m 94.070987,70.05187 c 1.42178,0 2.584919,-1.163133 2.584919,-2.58492 V 19.951698 c 0,-1.42028 -1.163139,-2.583413 -2.584919,-2.583413 H 46.555738 c -1.42178,0 -2.583423,1.163133 -2.583423,2.583413 l 0.0075,9.70728 h 40.38542 v 40.383919 z"
            style={{ fill: "#00a1e0" }}
          />
          <path
            d="m 43.979042,41.958498 -0.0075,19.410053 19.420523,-19.42052 z"
            style={{ fill: "#002856" }}
          />
          <path
            d="M 72.072721,70.05187 V 50.646297 L 52.685088,70.03543 Z"
            style={{ fill: "#002856" }}
          />
        </svg>
        <svg version="1.1" id="GrexManagerLogo-Text" viewBox="0 0 790 200">
          <path
            d="m 127.04672,113.26602 h 30.71104 l 0.2044,13.84807 q 0,15.12557 -5.72319,19.52015 -5.67209,4.34349 -25.39664,4.34349 -18.08935,0 -23.96585,-5.82538 Q 97,139.32696 97,121.3909 q 0,-22.892744 2.350587,-28.922529 2.912703,-7.358382 8.840283,-9.913381 5.92758,-2.554987 20.13336,-2.554987 18.60034,0 24.01694,3.985788 5.41659,3.934691 5.41659,17.527259 h -13.38818 q -0.35769,-6.847383 -3.01488,-8.482579 -2.6061,-1.686297 -13.18377,-1.686297 -11.49748,0 -14.61457,2.861593 -3.06599,2.861593 -3.06599,13.439263 l -0.0511,7.00069 0.10227,8.94248 q 0,10.37327 3.06599,13.23487 3.06599,2.81049 14.20576,2.81049 10.83316,0 13.74587,-2.40169 2.96379,-2.4017 2.96379,-11.34418 l 0.0511,-2.86159 h -17.5272 z"
            style={{ fill: "#002856" }}
          />
          <path
            d="m 174.90482,101.51305 h 11.70188 l -0.71538,6.84738 0.25549,0.0511 q 4.19019,-7.66498 13.18376,-7.66498 12.87718,0 12.87718,16.24976 V 120.42 h -11.03758 q 0.2044,-1.99289 0.2044,-2.60609 0,-7.81828 -6.08088,-7.81828 -8.68699,0 -8.68699,11.59967 v 28.76922 h -11.70188 z"
            style={{ fill: "#002856" }}
          />
          <path
            d="m 258.82065,134.72797 h 11.75297 v 1.8907 q 0,14.30796 -21.46196,14.30796 -14.56345,0 -19.06024,-4.95668 -4.49679,-4.95669 -4.49679,-21.00206 0,-14.25686 4.70119,-19.16245 4.70119,-4.90559 18.34484,-4.90559 13.08157,0 17.52727,4.80339 4.44569,4.75229 4.44569,18.75366 v 3.57699 h -33.06163 q -0.10226,1.63519 -0.10226,2.14619 0,7.20509 2.1973,9.60678 2.2484,2.4017 8.89139,2.4017 6.43859,0 8.38036,-1.3797 1.9418,-1.4308 1.9418,-6.08089 z m 0.10226,-13.84807 -0.0512,-1.94179 q 0,-5.82539 -2.04398,-7.56278 -1.9929,-1.7374 -8.73808,-1.7374 -6.54079,0 -8.53368,2.0951 -1.9418,2.09509 -1.9418,9.14687 z"
            style={{ fill: "#002856" }}
          />
          <path
            d="m 327.46798,101.51305 -14.20577,22.99495 15.78986,25.85653 H 315.0507 l -9.81117,-17.73165 -9.55568,17.73165 h -14.35907 l 15.99427,-25.70323 -14.10356,-23.14825 h 13.95025 l 8.07379,15.48326 8.27817,-15.48326 z"
            style={{ fill: "#002856" }}
          />
          <path
            d="m 424.48442,80.613203 v 69.751327 h -13.23488 v -38.0183 q 0,-4.5479 0.2555,-10.32218 l 0.25551,-5.212188 0.25549,-5.16109 h -0.4088 l -1.58409,4.85449 -1.533,4.854488 q -2.1462,6.54079 -3.32149,9.65788 l -15.32998,39.3469 h -12.05956 l -15.48326,-39.0403 q -1.2775,-3.2193 -3.3726,-9.65788 l -1.5841,-4.854488 -1.58409,-4.80339 h -0.4088 l 0.25549,5.058888 0.25551,5.10999 q 0.3066,5.92758 0.3066,10.16888 v 38.0183 H 342.929 V 80.613203 h 21.56415 l 12.46837,32.295117 q 1.2775,3.37259 3.37259,9.65788 l 1.533,4.85449 1.58409,4.80339 h 0.45991 l 1.48189,-4.80339 1.533,-4.80339 q 1.8907,-6.08089 3.27039,-9.60678 l 12.26396,-32.397317 z"
            style={{ fill: "#002856" }}
          />
          <path
            d="m 455.82743,115.66772 h -11.95737 q 0,-8.78918 4.088,-11.75298 4.08798,-2.96379 16.19865,-2.96379 13.18377,0 17.83386,3.62809 4.65008,3.62809 4.65008,13.84807 v 31.93742 h -11.70186 l 0.56209,-6.69408 -0.3066,-0.0511 q -3.37259,7.30729 -15.58545,7.30729 -17.73165,0 -17.73165,-15.07447 0,-15.22776 18.14045,-15.22776 12.11066,0 14.71677,5.56989 h 0.20439 v -7.92049 q 0,-5.72318 -1.9929,-7.56278 -1.99289,-1.89069 -8.17597,-1.89069 -8.94249,0 -8.94249,6.84738 z m 7.81828,12.82607 q -9.91337,0 -9.91337,6.84738 0,4.75229 2.044,6.23419 2.044,1.48189 8.58478,1.48189 10.67987,0 10.67987,-7.20508 0,-7.35838 -11.39528,-7.35838 z"
            style={{ fill: "#002856" }}
          />
          <path
            d="m 505.51535,101.51305 h 11.59966 l -0.45989,8.22708 0.25549,0.0511 q 3.4237,-8.84028 15.32996,-8.84028 17.32287,0 17.32287,16.14756 v 33.26601 H 537.86156 V 120.931 119.0914 l -0.25549,-3.42369 q -0.81762,-5.46769 -8.58479,-5.46769 -11.80408,0 -11.80408,11.19087 v 28.97363 h -11.70185 z"
            style={{ fill: "#002856" }}
          />
          <path
            d="m 580.46864,115.66772 h -11.95737 q 0,-8.78918 4.088,-11.75298 4.08797,-2.96379 16.19865,-2.96379 13.18377,0 17.83386,3.62809 4.65008,3.62809 4.65008,13.84807 v 31.93742 h -11.70187 l 0.5621,-6.69408 -0.3066,-0.0511 q -3.37258,7.30729 -15.58545,7.30729 -17.73165,0 -17.73165,-15.07447 0,-15.22776 18.14045,-15.22776 12.11067,0 14.71676,5.56989 h 0.20439 v -7.92049 q 0,-5.72318 -1.99288,-7.56278 -1.9929,-1.89069 -8.17598,-1.89069 -8.94249,0 -8.94249,6.84738 z m 7.81828,12.82607 q -9.91337,0 -9.91337,6.84738 0,4.75229 2.044,6.23419 2.04398,1.48189 8.58477,1.48189 10.67987,0 10.67987,-7.20508 0,-7.35838 -11.39527,-7.35838 z"
            style={{ fill: "#002856" }}
          />
          <path
            d="m 673.42832,101.51305 v 49.77128 q 0,11.95738 -4.8545,16.40306 -4.8034,4.4457 -17.78276,4.4457 -12.57057,0 -16.81185,-3.3726 -4.24129,-3.37259 -4.24129,-13.33707 h 11.34417 q 0,4.75229 1.78849,6.08089 1.7885,1.3797 7.97158,1.3797 10.88429,0 10.88429,-8.99358 v -10.98648 l -0.25551,-0.0511 q -3.11709,7.81828 -14.30796,7.81828 -11.08869,0 -15.17666,-5.36549 -4.03691,-5.36549 -4.03691,-20.03115 0,-13.79697 4.03691,-19.06026 4.08797,-5.26329 14.76786,-5.26329 11.65078,0 15.27886,8.63588 h 0.25549 l -0.56208,-8.07378 z m -23.04604,8.68698 q -6.74519,0 -8.68699,2.75939 -1.89071,2.7083 -1.89071,12.41727 0,10.32218 1.89071,13.18377 1.9418,2.8616 8.84028,2.8616 6.89848,0 8.94248,-2.9127 2.044,-2.96379 2.044,-13.03047 0,-9.65787 -2.044,-12.46837 -2.044,-2.81049 -9.09577,-2.81049 z"
            style={{ fill: "#002856" }}
          />
          <path
            d="m 723.4837,134.72797 h 11.75297 v 1.8907 q 0,14.30796 -21.46195,14.30796 -14.56347,0 -19.06024,-4.95668 -4.49679,-4.95669 -4.49679,-21.00206 0,-14.25686 4.70119,-19.16245 4.70118,-4.90559 18.34485,-4.90559 13.08157,0 17.52725,4.80339 4.44569,4.75229 4.44569,18.75366 v 3.57699 h -33.06162 q -0.10227,1.63519 -0.10227,2.14619 0,7.20509 2.19731,9.60678 2.2484,2.4017 8.89139,2.4017 6.43857,0 8.38037,-1.3797 1.94179,-1.4308 1.94179,-6.08089 z m 0.10227,-13.84807 -0.0512,-1.94179 q 0,-5.82539 -2.04397,-7.56278 -1.99291,-1.7374 -8.7381,-1.7374 -6.54078,0 -8.53368,2.0951 -1.9418,2.09509 -1.9418,9.14687 z"
            style={{ fill: "#002856" }}
          />
          <path
            d="m 752.69727,101.51305 h 11.70188 l -0.7154,6.84738 0.2555,0.0511 q 4.19018,-7.66498 13.18377,-7.66498 12.87717,0 12.87717,16.24976 V 120.42 h -11.03757 q 0.2044,-1.99289 0.2044,-2.60609 0,-7.81828 -6.08089,-7.81828 -8.68698,0 -8.68698,11.59967 v 28.76922 h -11.70188 z"
            style={{ fill: "#002856" }}
          />
        </svg>
      </div>
    );
  }
}
