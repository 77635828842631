import "./ButtonList.css";
import React from "react";

interface ButtonListProps {
  data: {
    title: string;
    callback?: () => void;
    disabled?: boolean;
    warning?: boolean;
  }[];
}

export default class ButtonList extends React.Component<ButtonListProps> {
  render() {
    return (
      <div id="ButtonList-ButtonHolder">
        {this.props.data.map((item, index) => (
          <div
            key={index}
            className={
              item.disabled !== undefined && item.disabled === true
                ? "ButtonList-Button disabled"
                : item.warning !== undefined && item.warning === true
                ? "ButtonList-Button warning"
                : "ButtonList-Button"
            }
            onClick={() => {
              if (
                item.callback !== undefined &&
                ((item.disabled !== undefined && item.disabled === false) ||
                  item.disabled === undefined)
              ) {
                item.callback();
              }
            }}
          >
            {item.title}
          </div>
        ))}
      </div>
    );
  }
}
