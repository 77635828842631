/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClientCreateTemplateModel,
  ClientTemplateModel,
  ClientTemplateModelInstance,
  ClientUpdateTemplateModelRequest,
  ClientUploadTemplateModelRequest,
} from '../models';
import {
    ClientCreateTemplateModelFromJSON,
    ClientCreateTemplateModelToJSON,
    ClientTemplateModelFromJSON,
    ClientTemplateModelToJSON,
    ClientTemplateModelInstanceFromJSON,
    ClientTemplateModelInstanceToJSON,
    ClientUpdateTemplateModelRequestFromJSON,
    ClientUpdateTemplateModelRequestToJSON,
    ClientUploadTemplateModelRequestFromJSON,
    ClientUploadTemplateModelRequestToJSON,
} from '../models';

export interface ApiGrexsuperadminClientTemplateModelCreateRequest {
    clientId: number;
    clientCreateTemplateModel: ClientCreateTemplateModel;
}

export interface ApiGrexsuperadminClientTemplateModelCreate2Request {
    clientId: number;
    templateModelId: number;
    clientUploadTemplateModelRequest: ClientUploadTemplateModelRequest;
}

export interface ApiGrexsuperadminClientTemplateModelDestroyRequest {
    clientId: number;
    templateModelId: number;
}

export interface ApiGrexsuperadminClientTemplateModelListRequest {
    clientId: number;
}

export interface ApiGrexsuperadminClientTemplateModelRetrieveRequest {
    clientId: number;
    templateModelId: number;
}

export interface ApiGrexsuperadminClientTemplateModelUpdateRequest {
    clientId: number;
    templateModelId: number;
    clientUpdateTemplateModelRequest: ClientUpdateTemplateModelRequest;
}

/**
 * 
 */
export class GrexSuperAdminTemplateModelApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a template model
     */
    async apiGrexsuperadminClientTemplateModelCreateRaw(requestParameters: ApiGrexsuperadminClientTemplateModelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientTemplateModel>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientTemplateModelCreate.');
        }

        if (requestParameters.clientCreateTemplateModel === null || requestParameters.clientCreateTemplateModel === undefined) {
            throw new runtime.RequiredError('clientCreateTemplateModel','Required parameter requestParameters.clientCreateTemplateModel was null or undefined when calling apiGrexsuperadminClientTemplateModelCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/template-model/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientCreateTemplateModelToJSON(requestParameters.clientCreateTemplateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientTemplateModelFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a template model
     */
    async apiGrexsuperadminClientTemplateModelCreate(requestParameters: ApiGrexsuperadminClientTemplateModelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientTemplateModel> {
        const response = await this.apiGrexsuperadminClientTemplateModelCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for uploading template model history
     */
    async apiGrexsuperadminClientTemplateModelCreate2Raw(requestParameters: ApiGrexsuperadminClientTemplateModelCreate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientTemplateModelCreate2.');
        }

        if (requestParameters.templateModelId === null || requestParameters.templateModelId === undefined) {
            throw new runtime.RequiredError('templateModelId','Required parameter requestParameters.templateModelId was null or undefined when calling apiGrexsuperadminClientTemplateModelCreate2.');
        }

        if (requestParameters.clientUploadTemplateModelRequest === null || requestParameters.clientUploadTemplateModelRequest === undefined) {
            throw new runtime.RequiredError('clientUploadTemplateModelRequest','Required parameter requestParameters.clientUploadTemplateModelRequest was null or undefined when calling apiGrexsuperadminClientTemplateModelCreate2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/template-model/{template_model_id}/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"template_model_id"}}`, encodeURIComponent(String(requestParameters.templateModelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientUploadTemplateModelRequestToJSON(requestParameters.clientUploadTemplateModelRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for uploading template model history
     */
    async apiGrexsuperadminClientTemplateModelCreate2(requestParameters: ApiGrexsuperadminClientTemplateModelCreate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexsuperadminClientTemplateModelCreate2Raw(requestParameters, initOverrides);
    }

    /**
     * delete TemplateModel
     */
    async apiGrexsuperadminClientTemplateModelDestroyRaw(requestParameters: ApiGrexsuperadminClientTemplateModelDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientTemplateModelDestroy.');
        }

        if (requestParameters.templateModelId === null || requestParameters.templateModelId === undefined) {
            throw new runtime.RequiredError('templateModelId','Required parameter requestParameters.templateModelId was null or undefined when calling apiGrexsuperadminClientTemplateModelDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/template-model/{template_model_id}/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"template_model_id"}}`, encodeURIComponent(String(requestParameters.templateModelId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete TemplateModel
     */
    async apiGrexsuperadminClientTemplateModelDestroy(requestParameters: ApiGrexsuperadminClientTemplateModelDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexsuperadminClientTemplateModelDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * get TemplateModel
     */
    async apiGrexsuperadminClientTemplateModelListRaw(requestParameters: ApiGrexsuperadminClientTemplateModelListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientTemplateModel>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientTemplateModelList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/template-model/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientTemplateModelFromJSON));
    }

    /**
     * get TemplateModel
     */
    async apiGrexsuperadminClientTemplateModelList(requestParameters: ApiGrexsuperadminClientTemplateModelListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientTemplateModel>> {
        const response = await this.apiGrexsuperadminClientTemplateModelListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get TemplateModel
     */
    async apiGrexsuperadminClientTemplateModelRetrieveRaw(requestParameters: ApiGrexsuperadminClientTemplateModelRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientTemplateModelInstance>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientTemplateModelRetrieve.');
        }

        if (requestParameters.templateModelId === null || requestParameters.templateModelId === undefined) {
            throw new runtime.RequiredError('templateModelId','Required parameter requestParameters.templateModelId was null or undefined when calling apiGrexsuperadminClientTemplateModelRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/template-model/{template_model_id}/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"template_model_id"}}`, encodeURIComponent(String(requestParameters.templateModelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientTemplateModelInstanceFromJSON(jsonValue));
    }

    /**
     * get TemplateModel
     */
    async apiGrexsuperadminClientTemplateModelRetrieve(requestParameters: ApiGrexsuperadminClientTemplateModelRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientTemplateModelInstance> {
        const response = await this.apiGrexsuperadminClientTemplateModelRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * put TemplateModel
     */
    async apiGrexsuperadminClientTemplateModelUpdateRaw(requestParameters: ApiGrexsuperadminClientTemplateModelUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling apiGrexsuperadminClientTemplateModelUpdate.');
        }

        if (requestParameters.templateModelId === null || requestParameters.templateModelId === undefined) {
            throw new runtime.RequiredError('templateModelId','Required parameter requestParameters.templateModelId was null or undefined when calling apiGrexsuperadminClientTemplateModelUpdate.');
        }

        if (requestParameters.clientUpdateTemplateModelRequest === null || requestParameters.clientUpdateTemplateModelRequest === undefined) {
            throw new runtime.RequiredError('clientUpdateTemplateModelRequest','Required parameter requestParameters.clientUpdateTemplateModelRequest was null or undefined when calling apiGrexsuperadminClientTemplateModelUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{client_id}/template-model/{template_model_id}/`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"template_model_id"}}`, encodeURIComponent(String(requestParameters.templateModelId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientUpdateTemplateModelRequestToJSON(requestParameters.clientUpdateTemplateModelRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * put TemplateModel
     */
    async apiGrexsuperadminClientTemplateModelUpdate(requestParameters: ApiGrexsuperadminClientTemplateModelUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexsuperadminClientTemplateModelUpdateRaw(requestParameters, initOverrides);
    }

}
