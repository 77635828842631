/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AttachmentVersionModelCreateRequest,
} from '../models';
import {
    AttachmentVersionModelCreateRequestFromJSON,
    AttachmentVersionModelCreateRequestToJSON,
} from '../models';

export interface ApiGrexmanagerAttachmentVersionModelCreateRequest {
    attachmentVersionModelCreateRequest: AttachmentVersionModelCreateRequest;
}

export interface ApiGrexmanagerAttachmentVersionModelDestroyRequest {
    id: number;
}

export interface ApiGrexmanagerAttachmentVersionModelRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class AttachmentVersionModelApi extends runtime.BaseAPI {

    /**
     * Endpoint for adding attachments to version models
     */
    async apiGrexmanagerAttachmentVersionModelCreateRaw(requestParameters: ApiGrexmanagerAttachmentVersionModelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.attachmentVersionModelCreateRequest === null || requestParameters.attachmentVersionModelCreateRequest === undefined) {
            throw new runtime.RequiredError('attachmentVersionModelCreateRequest','Required parameter requestParameters.attachmentVersionModelCreateRequest was null or undefined when calling apiGrexmanagerAttachmentVersionModelCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/attachment-version-model/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttachmentVersionModelCreateRequestToJSON(requestParameters.attachmentVersionModelCreateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for adding attachments to version models
     */
    async apiGrexmanagerAttachmentVersionModelCreate(requestParameters: ApiGrexmanagerAttachmentVersionModelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerAttachmentVersionModelCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for deleting an attachment
     */
    async apiGrexmanagerAttachmentVersionModelDestroyRaw(requestParameters: ApiGrexmanagerAttachmentVersionModelDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerAttachmentVersionModelDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/attachment-version-model/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting an attachment
     */
    async apiGrexmanagerAttachmentVersionModelDestroy(requestParameters: ApiGrexmanagerAttachmentVersionModelDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerAttachmentVersionModelDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for downloading an attachment from the server
     */
    async apiGrexmanagerAttachmentVersionModelRetrieveRaw(requestParameters: ApiGrexmanagerAttachmentVersionModelRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerAttachmentVersionModelRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/attachment-version-model/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Endpoint for downloading an attachment from the server
     */
    async apiGrexmanagerAttachmentVersionModelRetrieve(requestParameters: ApiGrexmanagerAttachmentVersionModelRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiGrexmanagerAttachmentVersionModelRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
