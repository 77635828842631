/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientUploadTemplateModelRequest
 */
export interface ClientUploadTemplateModelRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientUploadTemplateModelRequest
     */
    datasheetName: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUploadTemplateModelRequest
     */
    file: string;
}

/**
 * Check if a given object implements the ClientUploadTemplateModelRequest interface.
 */
export function instanceOfClientUploadTemplateModelRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "datasheetName" in value;
    isInstance = isInstance && "file" in value;

    return isInstance;
}

export function ClientUploadTemplateModelRequestFromJSON(json: any): ClientUploadTemplateModelRequest {
    return ClientUploadTemplateModelRequestFromJSONTyped(json, false);
}

export function ClientUploadTemplateModelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientUploadTemplateModelRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datasheetName': json['datasheet_name'],
        'file': json['file'],
    };
}

export function ClientUploadTemplateModelRequestToJSON(value?: ClientUploadTemplateModelRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datasheet_name': value.datasheetName,
        'file': value.file,
    };
}

