/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VersionModel
 */
export interface VersionModel {
    /**
     * 
     * @type {number}
     * @memberof VersionModel
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof VersionModel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VersionModel
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof VersionModel
     */
    readonly timestampLastModified: Date;
    /**
     * 
     * @type {number}
     * @memberof VersionModel
     */
    featureLayer: number;
    /**
     * 
     * @type {number}
     * @memberof VersionModel
     */
    author: number;
    /**
     * 
     * @type {number}
     * @memberof VersionModel
     */
    phase?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VersionModel
     */
    project: number;
    /**
     * 
     * @type {number}
     * @memberof VersionModel
     */
    viewer: number;
}

/**
 * Check if a given object implements the VersionModel interface.
 */
export function instanceOfVersionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "timestampLastModified" in value;
    isInstance = isInstance && "featureLayer" in value;
    isInstance = isInstance && "author" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "viewer" in value;

    return isInstance;
}

export function VersionModelFromJSON(json: any): VersionModel {
    return VersionModelFromJSONTyped(json, false);
}

export function VersionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'timestampLastModified': (new Date(json['timestamp_last_modified'])),
        'featureLayer': json['feature_layer'],
        'author': json['author'],
        'phase': !exists(json, 'phase') ? undefined : json['phase'],
        'project': json['project'],
        'viewer': json['viewer'],
    };
}

export function VersionModelToJSON(value?: VersionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'feature_layer': value.featureLayer,
        'author': value.author,
        'phase': value.phase,
        'project': value.project,
        'viewer': value.viewer,
    };
}

