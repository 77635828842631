import React from "react";
import "./FileInput.css";

interface FileInputProps {
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

class FileInput extends React.Component<FileInputProps> {
  render() {
    return (
      <input
        className="fileInput"
        type="file"
        onChange={this.props.onChange}
      ></input>
    );
  }
}

export default FileInput;
