/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoleEnum } from './RoleEnum';
import {
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * 
 * @export
 * @interface GrexUserList
 */
export interface GrexUserList {
    /**
     * 
     * @type {number}
     * @memberof GrexUserList
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof GrexUserList
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof GrexUserList
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof GrexUserList
     */
    email?: string;
    /**
     * 
     * @type {RoleEnum}
     * @memberof GrexUserList
     */
    role?: RoleEnum;
    /**
     * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
     * @type {boolean}
     * @memberof GrexUserList
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GrexUserList
     */
    mfaForced?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GrexUserList
     */
    mfaVerified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GrexUserList
     */
    blocked?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GrexUserList
     */
    lastLogin?: Date | null;
}

/**
 * Check if a given object implements the GrexUserList interface.
 */
export function instanceOfGrexUserList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function GrexUserListFromJSON(json: any): GrexUserList {
    return GrexUserListFromJSONTyped(json, false);
}

export function GrexUserListFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrexUserList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'role': !exists(json, 'role') ? undefined : RoleEnumFromJSON(json['role']),
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
        'mfaForced': !exists(json, 'mfa_forced') ? undefined : json['mfa_forced'],
        'mfaVerified': !exists(json, 'mfa_verified') ? undefined : json['mfa_verified'],
        'blocked': !exists(json, 'blocked') ? undefined : json['blocked'],
        'lastLogin': !exists(json, 'last_login') ? undefined : (json['last_login'] === null ? null : new Date(json['last_login'])),
    };
}

export function GrexUserListToJSON(value?: GrexUserList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'role': RoleEnumToJSON(value.role),
        'is_active': value.isActive,
        'mfa_forced': value.mfaForced,
        'mfa_verified': value.mfaVerified,
        'blocked': value.blocked,
        'last_login': value.lastLogin === undefined ? undefined : (value.lastLogin === null ? null : value.lastLogin.toISOString()),
    };
}

