import React from 'react'
import './CheckBox.css';

interface CheckBoxProps {
    checked: boolean, 
    onClick: (checked: boolean) => void
}

export default class CheckBox extends React.Component<CheckBoxProps> {

    onClick = () => {
        this.props.onClick(!this.props.checked);
    }

    render() {

        return(
            <div className={this.props.checked ? 'CheckBox-CheckBox CheckBox-Checked' : 'CheckBox-CheckBox'} onClick={this.onClick}>
                {(()=>{
                    if(this.props.checked) {
                        return (<div className='CheckBox-CheckMark'></div>);
                    }
                })()} 
            </div>
        );
    }
}