/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentationTypeEnum } from './DocumentationTypeEnum';
import {
    DocumentationTypeEnumFromJSON,
    DocumentationTypeEnumFromJSONTyped,
    DocumentationTypeEnumToJSON,
} from './DocumentationTypeEnum';

/**
 * 
 * @export
 * @interface DocumentationSuperAdmin
 */
export interface DocumentationSuperAdmin {
    /**
     * 
     * @type {number}
     * @memberof DocumentationSuperAdmin
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentationSuperAdmin
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentationSuperAdmin
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentationSuperAdmin
     */
    pdfPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentationSuperAdmin
     */
    videoUrl?: string | null;
    /**
     * 
     * @type {DocumentationTypeEnum}
     * @memberof DocumentationSuperAdmin
     */
    documentationType: DocumentationTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof DocumentationSuperAdmin
     */
    access: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof DocumentationSuperAdmin
     */
    file?: string;
}

/**
 * Check if a given object implements the DocumentationSuperAdmin interface.
 */
export function instanceOfDocumentationSuperAdmin(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "documentationType" in value;
    isInstance = isInstance && "access" in value;

    return isInstance;
}

export function DocumentationSuperAdminFromJSON(json: any): DocumentationSuperAdmin {
    return DocumentationSuperAdminFromJSONTyped(json, false);
}

export function DocumentationSuperAdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentationSuperAdmin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'pdfPath': !exists(json, 'pdf_path') ? undefined : json['pdf_path'],
        'videoUrl': !exists(json, 'video_url') ? undefined : json['video_url'],
        'documentationType': DocumentationTypeEnumFromJSON(json['documentationType']),
        'access': json['access'],
        'file': !exists(json, 'file') ? undefined : json['file'],
    };
}

export function DocumentationSuperAdminToJSON(value?: DocumentationSuperAdmin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'pdf_path': value.pdfPath,
        'video_url': value.videoUrl,
        'documentationType': DocumentationTypeEnumToJSON(value.documentationType),
        'access': value.access,
        'file': value.file,
    };
}

