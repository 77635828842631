/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ADM` - Admin
 * * `NON` - None
 * * `USR` - User
 * * `VWR` - Viewer
 * @export
 */
export const RoleEnum = {
    Adm: 'ADM',
    Non: 'NON',
    Usr: 'USR',
    Vwr: 'VWR'
} as const;
export type RoleEnum = typeof RoleEnum[keyof typeof RoleEnum];


export function RoleEnumFromJSON(json: any): RoleEnum {
    return RoleEnumFromJSONTyped(json, false);
}

export function RoleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleEnum {
    return json as RoleEnum;
}

export function RoleEnumToJSON(value?: RoleEnum | null): any {
    return value as any;
}

