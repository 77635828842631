import React from "react";
import "./Section.css";

interface SectionProps {
  children?: React.ReactNode;
  disabled?: boolean;
  id?: string;
}

export default class Section extends React.Component<SectionProps> {
  render() {
    return (
      <div className="Section" id={this.props.id}>
        {this.props.children}
        <div
          className={
            this.props.disabled !== undefined && this.props.disabled === true
              ? "Section-DisabledOverlay disabled"
              : "Section-DisabledOverlay"
          }
        ></div>
      </div>
    );
  }
}
