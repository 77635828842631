import "./Button.css";
import React from "react";

interface ButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export default class Button extends React.Component<ButtonProps> {
  render() {
    return (
      <div
        className={
          this.props.disabled !== undefined && this.props.disabled === true
            ? "Button disabled"
            : "Button"
        }
        onClick={() => {
          if (this.props.onClick !== undefined) {
            this.props.onClick();
          }
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
