import "./Alert.css";
import React from "react";

interface AlertProps {
  Error: boolean;
  Message: string;
}

export default class Alert extends React.Component<AlertProps> {
  render() {
    if (this.props.Error) {
      return (
        <div className="ErrorMessage">
          <p>{this.props.Message}</p>
        </div>
      );
    } else {
      return (
        <div className="InfoMessage">
          <p>{this.props.Message}</p>
        </div>
      );
    }
  }
}
