/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PatchedSubregionUpdateRequest,
  SubregionCreateRequest,
  SubregionListCreateResponse,
  SubregionListRolesResponse,
  SubregionRetrieveResponse,
  SubregionUpdate,
  SubregionUpdateRequest,
} from '../models';
import {
    PatchedSubregionUpdateRequestFromJSON,
    PatchedSubregionUpdateRequestToJSON,
    SubregionCreateRequestFromJSON,
    SubregionCreateRequestToJSON,
    SubregionListCreateResponseFromJSON,
    SubregionListCreateResponseToJSON,
    SubregionListRolesResponseFromJSON,
    SubregionListRolesResponseToJSON,
    SubregionRetrieveResponseFromJSON,
    SubregionRetrieveResponseToJSON,
    SubregionUpdateFromJSON,
    SubregionUpdateToJSON,
    SubregionUpdateRequestFromJSON,
    SubregionUpdateRequestToJSON,
} from '../models';

export interface ApiGrexmanagerSubregionCreateRequest {
    subregionCreateRequest: SubregionCreateRequest;
}

export interface ApiGrexmanagerSubregionDestroyRequest {
    id: number;
    force?: boolean;
}

export interface ApiGrexmanagerSubregionList2Request {
    id: number;
}

export interface ApiGrexmanagerSubregionPartialUpdateRequest {
    id: number;
    patchedSubregionUpdateRequest?: PatchedSubregionUpdateRequest;
}

export interface ApiGrexmanagerSubregionRolesListRequest {
    id: number;
}

export interface ApiGrexmanagerSubregionUpdateRequest {
    id: number;
    subregionUpdateRequest: SubregionUpdateRequest;
}

/**
 * 
 */
export class SubregionApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a subregion
     */
    async apiGrexmanagerSubregionCreateRaw(requestParameters: ApiGrexmanagerSubregionCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubregionListCreateResponse>> {
        if (requestParameters.subregionCreateRequest === null || requestParameters.subregionCreateRequest === undefined) {
            throw new runtime.RequiredError('subregionCreateRequest','Required parameter requestParameters.subregionCreateRequest was null or undefined when calling apiGrexmanagerSubregionCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/subregion/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubregionCreateRequestToJSON(requestParameters.subregionCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubregionListCreateResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a subregion
     */
    async apiGrexmanagerSubregionCreate(requestParameters: ApiGrexmanagerSubregionCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubregionListCreateResponse> {
        const response = await this.apiGrexmanagerSubregionCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting a subregion
     */
    async apiGrexmanagerSubregionDestroyRaw(requestParameters: ApiGrexmanagerSubregionDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerSubregionDestroy.');
        }

        const queryParameters: any = {};

        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/subregion/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting a subregion
     */
    async apiGrexmanagerSubregionDestroy(requestParameters: ApiGrexmanagerSubregionDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerSubregionDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for receiving a list of subregions
     */
    async apiGrexmanagerSubregionListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubregionListCreateResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/subregion/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubregionListCreateResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of subregions
     */
    async apiGrexmanagerSubregionList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubregionListCreateResponse>> {
        const response = await this.apiGrexmanagerSubregionListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiveing information about a subregion
     */
    async apiGrexmanagerSubregionList2Raw(requestParameters: ApiGrexmanagerSubregionList2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubregionRetrieveResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerSubregionList2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/subregion/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubregionRetrieveResponseFromJSON));
    }

    /**
     * Endpoint for receiveing information about a subregion
     */
    async apiGrexmanagerSubregionList2(requestParameters: ApiGrexmanagerSubregionList2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubregionRetrieveResponse>> {
        const response = await this.apiGrexmanagerSubregionList2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating a subregion
     */
    async apiGrexmanagerSubregionPartialUpdateRaw(requestParameters: ApiGrexmanagerSubregionPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerSubregionPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/subregion/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedSubregionUpdateRequestToJSON(requestParameters.patchedSubregionUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for updating a subregion
     */
    async apiGrexmanagerSubregionPartialUpdate(requestParameters: ApiGrexmanagerSubregionPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerSubregionPartialUpdateRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for listing the user roles based on the subregion
     */
    async apiGrexmanagerSubregionRolesListRaw(requestParameters: ApiGrexmanagerSubregionRolesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubregionListRolesResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerSubregionRolesList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/subregion/{id}/roles/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubregionListRolesResponseFromJSON));
    }

    /**
     * Endpoint for listing the user roles based on the subregion
     */
    async apiGrexmanagerSubregionRolesList(requestParameters: ApiGrexmanagerSubregionRolesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubregionListRolesResponse>> {
        const response = await this.apiGrexmanagerSubregionRolesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating a subregion
     */
    async apiGrexmanagerSubregionUpdateRaw(requestParameters: ApiGrexmanagerSubregionUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubregionUpdate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerSubregionUpdate.');
        }

        if (requestParameters.subregionUpdateRequest === null || requestParameters.subregionUpdateRequest === undefined) {
            throw new runtime.RequiredError('subregionUpdateRequest','Required parameter requestParameters.subregionUpdateRequest was null or undefined when calling apiGrexmanagerSubregionUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/subregion/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubregionUpdateRequestToJSON(requestParameters.subregionUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubregionUpdateFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating a subregion
     */
    async apiGrexmanagerSubregionUpdate(requestParameters: ApiGrexmanagerSubregionUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubregionUpdate> {
        const response = await this.apiGrexmanagerSubregionUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
