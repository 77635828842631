import React from "react";
import QRCode from "qrcode.react";

interface QrCodeProps {
  onClick?: () => void;
  url: string;
}

class QrCode extends React.Component<QrCodeProps> {
  render() {
    return (
      <div>
        <QRCode value={this.props.url} fgColor="#002856" level="H" />
      </div>
    );
  }
}

export default QrCode;
