/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SavedModelHistoryResponse
 */
export interface SavedModelHistoryResponse {
    /**
     * 
     * @type {number}
     * @memberof SavedModelHistoryResponse
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof SavedModelHistoryResponse
     */
    readonly timestampSave: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SavedModelHistoryResponse
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SavedModelHistoryResponse
     */
    filesize?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SavedModelHistoryResponse
     */
    filenameOrig: string;
    /**
     * 
     * @type {string}
     * @memberof SavedModelHistoryResponse
     */
    userSave: string;
}

/**
 * Check if a given object implements the SavedModelHistoryResponse interface.
 */
export function instanceOfSavedModelHistoryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "timestampSave" in value;
    isInstance = isInstance && "filenameOrig" in value;
    isInstance = isInstance && "userSave" in value;

    return isInstance;
}

export function SavedModelHistoryResponseFromJSON(json: any): SavedModelHistoryResponse {
    return SavedModelHistoryResponseFromJSONTyped(json, false);
}

export function SavedModelHistoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavedModelHistoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'timestampSave': (new Date(json['timestamp_save'])),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'filesize': !exists(json, 'filesize') ? undefined : json['filesize'],
        'filenameOrig': json['filename_orig'],
        'userSave': json['user_save'],
    };
}

export function SavedModelHistoryResponseToJSON(value?: SavedModelHistoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'filesize': value.filesize,
        'filename_orig': value.filenameOrig,
        'user_save': value.userSave,
    };
}

