/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LogResponse,
} from '../models';
import {
    LogResponseFromJSON,
    LogResponseToJSON,
} from '../models';

export interface ApiGrexmanagerLogRetrieveRequest {
    logRows: number;
    page: number;
    clientId?: number;
}

/**
 * 
 */
export class LogApi extends runtime.BaseAPI {

    /**
     * Endpoint for receiving a list of logs
     */
    async apiGrexmanagerLogRetrieveRaw(requestParameters: ApiGrexmanagerLogRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LogResponse>> {
        if (requestParameters.logRows === null || requestParameters.logRows === undefined) {
            throw new runtime.RequiredError('logRows','Required parameter requestParameters.logRows was null or undefined when calling apiGrexmanagerLogRetrieve.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling apiGrexmanagerLogRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.logRows !== undefined) {
            queryParameters['log_rows'] = requestParameters.logRows;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/log/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LogResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiving a list of logs
     */
    async apiGrexmanagerLogRetrieve(requestParameters: ApiGrexmanagerLogRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LogResponse> {
        const response = await this.apiGrexmanagerLogRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
