/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PatchedVersionModelUpdateRequest,
  VersionModel,
  VersionModelCreateRequest,
  VersionModelCreateResponse,
  VersionModelInfoResponse,
  VersionModelListResponse,
  VersionModelMoveRequest,
  VersionModelUpdateRequest,
} from '../models';
import {
    PatchedVersionModelUpdateRequestFromJSON,
    PatchedVersionModelUpdateRequestToJSON,
    VersionModelFromJSON,
    VersionModelToJSON,
    VersionModelCreateRequestFromJSON,
    VersionModelCreateRequestToJSON,
    VersionModelCreateResponseFromJSON,
    VersionModelCreateResponseToJSON,
    VersionModelInfoResponseFromJSON,
    VersionModelInfoResponseToJSON,
    VersionModelListResponseFromJSON,
    VersionModelListResponseToJSON,
    VersionModelMoveRequestFromJSON,
    VersionModelMoveRequestToJSON,
    VersionModelUpdateRequestFromJSON,
    VersionModelUpdateRequestToJSON,
} from '../models';

export interface ApiGrexmanagerVersionModelCreateRequest {
    versionModelCreateRequest: VersionModelCreateRequest;
}

export interface ApiGrexmanagerVersionModelDestroyRequest {
    id: number;
}

export interface ApiGrexmanagerVersionModelDuplicateRetrieveRequest {
    id: number;
}

export interface ApiGrexmanagerVersionModelListRequest {
    projectId: number;
}

export interface ApiGrexmanagerVersionModelMoveCreateRequest {
    id: number;
    versionModelMoveRequest: VersionModelMoveRequest;
}

export interface ApiGrexmanagerVersionModelPartialUpdateRequest {
    id: number;
    patchedVersionModelUpdateRequest?: PatchedVersionModelUpdateRequest;
}

export interface ApiGrexmanagerVersionModelRetrieveRequest {
    id: number;
}

export interface ApiGrexmanagerVersionModelUpdateRequest {
    id: number;
    versionModelUpdateRequest: VersionModelUpdateRequest;
}

/**
 * 
 */
export class VersionModelApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a nieuw version model
     */
    async apiGrexmanagerVersionModelCreateRaw(requestParameters: ApiGrexmanagerVersionModelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VersionModelCreateResponse>> {
        if (requestParameters.versionModelCreateRequest === null || requestParameters.versionModelCreateRequest === undefined) {
            throw new runtime.RequiredError('versionModelCreateRequest','Required parameter requestParameters.versionModelCreateRequest was null or undefined when calling apiGrexmanagerVersionModelCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/version-model/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VersionModelCreateRequestToJSON(requestParameters.versionModelCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionModelCreateResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a nieuw version model
     */
    async apiGrexmanagerVersionModelCreate(requestParameters: ApiGrexmanagerVersionModelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VersionModelCreateResponse> {
        const response = await this.apiGrexmanagerVersionModelCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting a version model information
     */
    async apiGrexmanagerVersionModelDestroyRaw(requestParameters: ApiGrexmanagerVersionModelDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerVersionModelDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/version-model/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting a version model information
     */
    async apiGrexmanagerVersionModelDestroy(requestParameters: ApiGrexmanagerVersionModelDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerVersionModelDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for duplicating a version model
     */
    async apiGrexmanagerVersionModelDuplicateRetrieveRaw(requestParameters: ApiGrexmanagerVersionModelDuplicateRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VersionModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerVersionModelDuplicateRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/version-model/{id}/duplicate/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionModelFromJSON(jsonValue));
    }

    /**
     * Endpoint for duplicating a version model
     */
    async apiGrexmanagerVersionModelDuplicateRetrieve(requestParameters: ApiGrexmanagerVersionModelDuplicateRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VersionModel> {
        const response = await this.apiGrexmanagerVersionModelDuplicateRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving a list of versions for a spicific project
     */
    async apiGrexmanagerVersionModelListRaw(requestParameters: ApiGrexmanagerVersionModelListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VersionModelListResponse>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling apiGrexmanagerVersionModelList.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/version-model/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VersionModelListResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of versions for a spicific project
     */
    async apiGrexmanagerVersionModelList(requestParameters: ApiGrexmanagerVersionModelListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VersionModelListResponse>> {
        const response = await this.apiGrexmanagerVersionModelListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for moving a version model to a different project
     */
    async apiGrexmanagerVersionModelMoveCreateRaw(requestParameters: ApiGrexmanagerVersionModelMoveCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VersionModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerVersionModelMoveCreate.');
        }

        if (requestParameters.versionModelMoveRequest === null || requestParameters.versionModelMoveRequest === undefined) {
            throw new runtime.RequiredError('versionModelMoveRequest','Required parameter requestParameters.versionModelMoveRequest was null or undefined when calling apiGrexmanagerVersionModelMoveCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/version-model/{id}/move/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VersionModelMoveRequestToJSON(requestParameters.versionModelMoveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionModelFromJSON(jsonValue));
    }

    /**
     * Endpoint for moving a version model to a different project
     */
    async apiGrexmanagerVersionModelMoveCreate(requestParameters: ApiGrexmanagerVersionModelMoveCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VersionModel> {
        const response = await this.apiGrexmanagerVersionModelMoveCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for partially updating version model information
     */
    async apiGrexmanagerVersionModelPartialUpdateRaw(requestParameters: ApiGrexmanagerVersionModelPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VersionModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerVersionModelPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/version-model/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedVersionModelUpdateRequestToJSON(requestParameters.patchedVersionModelUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionModelFromJSON(jsonValue));
    }

    /**
     * Endpoint for partially updating version model information
     */
    async apiGrexmanagerVersionModelPartialUpdate(requestParameters: ApiGrexmanagerVersionModelPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VersionModel> {
        const response = await this.apiGrexmanagerVersionModelPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving more detailed information about a version if you have access to it
     */
    async apiGrexmanagerVersionModelRetrieveRaw(requestParameters: ApiGrexmanagerVersionModelRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VersionModelInfoResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerVersionModelRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/version-model/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionModelInfoResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiving more detailed information about a version if you have access to it
     */
    async apiGrexmanagerVersionModelRetrieve(requestParameters: ApiGrexmanagerVersionModelRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VersionModelInfoResponse> {
        const response = await this.apiGrexmanagerVersionModelRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating version model information
     */
    async apiGrexmanagerVersionModelUpdateRaw(requestParameters: ApiGrexmanagerVersionModelUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VersionModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerVersionModelUpdate.');
        }

        if (requestParameters.versionModelUpdateRequest === null || requestParameters.versionModelUpdateRequest === undefined) {
            throw new runtime.RequiredError('versionModelUpdateRequest','Required parameter requestParameters.versionModelUpdateRequest was null or undefined when calling apiGrexmanagerVersionModelUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/version-model/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VersionModelUpdateRequestToJSON(requestParameters.versionModelUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionModelFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating version model information
     */
    async apiGrexmanagerVersionModelUpdate(requestParameters: ApiGrexmanagerVersionModelUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VersionModel> {
        const response = await this.apiGrexmanagerVersionModelUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
