/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TokenRefresh,
} from '../models';
import {
    TokenRefreshFromJSON,
    TokenRefreshToJSON,
} from '../models';

export interface ApiGrexmanagerTokenRefreshCreateRequest {
    tokenRefresh: TokenRefresh;
}

/**
 * 
 */
export class TokenApi extends runtime.BaseAPI {

    /**
     * Endpoint for updating the refresh and access tokens
     */
    async apiGrexmanagerTokenRefreshCreateRaw(requestParameters: ApiGrexmanagerTokenRefreshCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenRefresh>> {
        if (requestParameters.tokenRefresh === null || requestParameters.tokenRefresh === undefined) {
            throw new runtime.RequiredError('tokenRefresh','Required parameter requestParameters.tokenRefresh was null or undefined when calling apiGrexmanagerTokenRefreshCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/grexmanager/token/refresh/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRefreshToJSON(requestParameters.tokenRefresh),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenRefreshFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating the refresh and access tokens
     */
    async apiGrexmanagerTokenRefreshCreate(requestParameters: ApiGrexmanagerTokenRefreshCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenRefresh> {
        const response = await this.apiGrexmanagerTokenRefreshCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating the refresh and access tokens
     */
    async apiGrexmanagerTokenRefreshRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenRefresh>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/grexmanager/token/refresh/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenRefreshFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating the refresh and access tokens
     */
    async apiGrexmanagerTokenRefreshRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenRefresh> {
        const response = await this.apiGrexmanagerTokenRefreshRetrieveRaw(initOverrides);
        return await response.value();
    }

}
