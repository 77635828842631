/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoleEnum } from './RoleEnum';
import {
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * 
 * @export
 * @interface GrexUserUpdate
 */
export interface GrexUserUpdate {
    /**
     * 
     * @type {string}
     * @memberof GrexUserUpdate
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof GrexUserUpdate
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof GrexUserUpdate
     */
    email?: string;
    /**
     * 
     * @type {RoleEnum}
     * @memberof GrexUserUpdate
     */
    role?: RoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GrexUserUpdate
     */
    mfaForced?: boolean;
}

/**
 * Check if a given object implements the GrexUserUpdate interface.
 */
export function instanceOfGrexUserUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GrexUserUpdateFromJSON(json: any): GrexUserUpdate {
    return GrexUserUpdateFromJSONTyped(json, false);
}

export function GrexUserUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrexUserUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'role': !exists(json, 'role') ? undefined : RoleEnumFromJSON(json['role']),
        'mfaForced': !exists(json, 'mfa_forced') ? undefined : json['mfa_forced'],
    };
}

export function GrexUserUpdateToJSON(value?: GrexUserUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'role': RoleEnumToJSON(value.role),
        'mfa_forced': value.mfaForced,
    };
}

