/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PatchedPhase,
  Phase,
  PhaseWithId,
} from '../models';
import {
    PatchedPhaseFromJSON,
    PatchedPhaseToJSON,
    PhaseFromJSON,
    PhaseToJSON,
    PhaseWithIdFromJSON,
    PhaseWithIdToJSON,
} from '../models';

export interface ApiGrexmanagerPhaseCreateRequest {
    phase?: Phase;
}

export interface ApiGrexmanagerPhaseDestroyRequest {
    id: number;
}

export interface ApiGrexmanagerPhasePartialUpdateRequest {
    id: number;
    patchedPhase?: PatchedPhase;
}

export interface ApiGrexmanagerPhaseRetrieveRequest {
    id: number;
}

export interface ApiGrexmanagerPhaseUpdateRequest {
    id: number;
    phase?: Phase;
}

/**
 * 
 */
export class PhaseApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a phase
     */
    async apiGrexmanagerPhaseCreateRaw(requestParameters: ApiGrexmanagerPhaseCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PhaseWithId>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/phase/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhaseToJSON(requestParameters.phase),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhaseWithIdFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a phase
     */
    async apiGrexmanagerPhaseCreate(requestParameters: ApiGrexmanagerPhaseCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhaseWithId> {
        const response = await this.apiGrexmanagerPhaseCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting a phase
     */
    async apiGrexmanagerPhaseDestroyRaw(requestParameters: ApiGrexmanagerPhaseDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerPhaseDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/phase/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting a phase
     */
    async apiGrexmanagerPhaseDestroy(requestParameters: ApiGrexmanagerPhaseDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerPhaseDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for receiving a list of phases
     */
    async apiGrexmanagerPhaseListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PhaseWithId>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/phase/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhaseWithIdFromJSON));
    }

    /**
     * Endpoint for receiving a list of phases
     */
    async apiGrexmanagerPhaseList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PhaseWithId>> {
        const response = await this.apiGrexmanagerPhaseListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating a phase
     */
    async apiGrexmanagerPhasePartialUpdateRaw(requestParameters: ApiGrexmanagerPhasePartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PhaseWithId>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerPhasePartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/phase/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedPhaseToJSON(requestParameters.patchedPhase),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhaseWithIdFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating a phase
     */
    async apiGrexmanagerPhasePartialUpdate(requestParameters: ApiGrexmanagerPhasePartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhaseWithId> {
        const response = await this.apiGrexmanagerPhasePartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiveing information about a phase
     */
    async apiGrexmanagerPhaseRetrieveRaw(requestParameters: ApiGrexmanagerPhaseRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PhaseWithId>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerPhaseRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/phase/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhaseWithIdFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiveing information about a phase
     */
    async apiGrexmanagerPhaseRetrieve(requestParameters: ApiGrexmanagerPhaseRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhaseWithId> {
        const response = await this.apiGrexmanagerPhaseRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating a phase
     */
    async apiGrexmanagerPhaseUpdateRaw(requestParameters: ApiGrexmanagerPhaseUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PhaseWithId>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerPhaseUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/phase/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PhaseToJSON(requestParameters.phase),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhaseWithIdFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating a phase
     */
    async apiGrexmanagerPhaseUpdate(requestParameters: ApiGrexmanagerPhaseUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhaseWithId> {
        const response = await this.apiGrexmanagerPhaseUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
