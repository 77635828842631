/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.10.25.15.09
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoleEnum } from './RoleEnum';
import {
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * 
 * @export
 * @interface GrexUserProjectRoleListResponse
 */
export interface GrexUserProjectRoleListResponse {
    /**
     * 
     * @type {number}
     * @memberof GrexUserProjectRoleListResponse
     */
    projectId: number;
    /**
     * 
     * @type {string}
     * @memberof GrexUserProjectRoleListResponse
     */
    projectName: string;
    /**
     * 
     * @type {RoleEnum}
     * @memberof GrexUserProjectRoleListResponse
     */
    role: RoleEnum;
    /**
     * 
     * @type {number}
     * @memberof GrexUserProjectRoleListResponse
     */
    user: number;
}

/**
 * Check if a given object implements the GrexUserProjectRoleListResponse interface.
 */
export function instanceOfGrexUserProjectRoleListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "projectName" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function GrexUserProjectRoleListResponseFromJSON(json: any): GrexUserProjectRoleListResponse {
    return GrexUserProjectRoleListResponseFromJSONTyped(json, false);
}

export function GrexUserProjectRoleListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrexUserProjectRoleListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'projectName': json['project_name'],
        'role': RoleEnumFromJSON(json['role']),
        'user': json['user'],
    };
}

export function GrexUserProjectRoleListResponseToJSON(value?: GrexUserProjectRoleListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_id': value.projectId,
        'project_name': value.projectName,
        'role': RoleEnumToJSON(value.role),
        'user': value.user,
    };
}

